import * as React from 'react';

import { Page, ToS } from '../../../fragments';

const ToSPage = () => {
  return (
    <Page title="Kokolingo">
      <ToS />
    </Page>
  );
};

export default ToSPage;
