import React from 'react';
import { AshaFragment, PageNew } from '../../../fragments';
import { AshaHeader } from '../../../components';

const Asha = () => {
  return (
    <PageNew
      title="Asha"
      description="Asha"
      headline="ASHA2022"
      wrapper={(props) => <div {...props} />}
    >
      <AshaHeader />
      <AshaFragment />
    </PageNew>
  );
};

export default Asha;
