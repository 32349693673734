import * as React from 'react';
import { Localize, useIntlMessages } from '@kokolingo/localisation';
import Slider from 'react-slick';
import {
  ActivityCard,
  Button,
  Paragraph,
  Title,
  Logo,
  Video,
} from '../../../components';

import {
  Page,
  appScreenshots,
  shopScreenshots,
  PricingTable,
  WhyCard,
  Banner,
} from '../../../fragments';

import activityCardItems from '../../../constants/activityCardItems';

import testimonials from '../../../constants/testimonials';

import kokoTheParrot2 from '../../../images/papiga_koko_2.svg';
import videos from '../../../videos';
import kokoTheParrot1 from '../../../images/papiga_koko_1.svg';
import jungle from '../../../images/dzungla.png';

import './sliders.scss';

const IndexPage = () => {
  const messages = useIntlMessages();

  const settings = {
    slidesToShow: 1,
    infinite: true,
    className: 'slick-slider-home',
    dots: true,
    arrows: true,
    swipe: true,
    swipeToSlide: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          centerMode: true,
        },
      },
    ],
  };

  return (
    <Page title="Kokolingo" description={messages.meta.KokolingoDescription}>
      <div
        className="modal fade"
        id="basicModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        data-show="true"
        aria-hidden="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                <b>Iskoristite priliku i prijavite se na BESPLATNI WEBINAR</b>o
                digitalnoj logopedskoj vježbenici Kokolingo.
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-block">
                <p>
                  Za <b>RODITELJE</b> djece s artikulacijskim teškoćama:
                  <br />
                  <span className="light-bold">
                    UTORAK, 24. studenog 2020. u 18:00 sati.
                  </span>
                </p>
                <button
                  type="button"
                  className="light-bold modal-button"
                  onClick={() =>
                    window.open(
                      'https://www.kokolingo.hr/roditelji-edukacija',
                      '_blank'
                    )
                  }
                >
                  PRIJAVA ZA RODITELJE
                </button>
              </div>
              <div className="modal-block">
                <p>
                  Za sve <b>STRUČNJAKE</b> koji rade s djecom predškolske i rane
                  školske dobi:
                  <br />
                  <span className="light-bold">
                    SRIJEDA, 2. prosinca 2020. u 18:00 sati
                  </span>
                </p>
                <button
                  type="button"
                  className="light-bold modal-button"
                  onClick={() =>
                    window.open(
                      'https://www.kokolingo.hr/skole-edukacija',
                      '_blank'
                    )
                  }
                >
                  PRIJAVA ZA STRUČNJAKE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Banner />
      <Logo />
      <div className="block1-bg">
        <div className="block1-container text-center">
          <Title variant="dark" className="block1-title">
            <Localize id="homePage.Title" />
          </Title>
          <Paragraph>
            <Localize id="homePage.Introduction" />
          </Paragraph>
          <Localize.Img
            titleID="homePage.KokoTheParrot"
            altID="homePage.KokoTheParrot"
            src={kokoTheParrot2}
            className="block1-parrot"
          />
        </div>
      </div>
      <div className="block2-bg">
        <div className="block2-container">
          <Title variant="light">
            <Localize id="homePage.WhyChoose" />
          </Title>
          <WhyCard />
        </div>
      </div>
      <Video src={videos.KOKOLINGO} />
      <div className="activities">
        <div className="z">
          <div className="activities-container">
            <Title variant="light" className="no-margin">
              <Localize id="homePage.ActivitiesTitle" />
            </Title>
            <Paragraph className="activities-paragraph">
              <Localize id="homePage.ActivitiesDescription" />
            </Paragraph>
          </div>

          <Slider {...settings}>
            {appScreenshots.map((element, index) => (
              <React.Fragment key={index}>{element}</React.Fragment>
            ))}
          </Slider>
        </div>
      </div>
      <div className="activity-groups">
        <div className="activity-groups-container container-fluid">
          <div className="row no-gutters">
            {activityCardItems.map((element, index) => (
              <ActivityCard
                key={index}
                title={element.title}
                description={element.description}
                img={element.img}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="activities2">
        <div className="activities-container" style={{ marginTop: '100px' }}>
          <Title variant="dark" className="title-padding">
            <Localize id="homePage.ShopTitle" />
          </Title>
          <Paragraph className="activities-paragraph">
            <Localize id="homePage.ShopDescription" />
          </Paragraph>
        </div>

        <div className="z">
          <Slider {...settings}>
            {shopScreenshots.map((element, index) => (
              <React.Fragment key={index}>{element}</React.Fragment>
            ))}
          </Slider>
        </div>
      </div>
      <span className="anchor" id="recenzije-korisnika" />
      <div className="block5-bg container-fluid">
        <Title variant="light">
          <Localize id="homePage.Testimonial" />
        </Title>

        <Slider {...settings}>
          {testimonials.TESTIMONIALS.map((element, index) => (
            <div key={index}>
              <div className="mySlides">
                <div className="row text-left">
                  <div className="col-xl-4">{element.img}</div>
                  <div className="col-xl-8 testimonial-text">
                    {element.imgDesc}
                  </div>
                </div>
                <div className="testimonial-name bottom2">
                  <div className="col-12">{element.imgName}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <span className="anchor" id="cijene-za-roditelje" />
      <span className="anchor" id="cijene-za-ustanove" />
      <div className="block4-bg-revert">
        <div className="block4-container container-fluid">
          <PricingTable />
        </div>
      </div>
      <div className="block6-bg-revert">
        <div className="block6-container">
          <Title variant="dark" className="end-title">
            <Localize id="homePage.Trial" />
          </Title>
          <Button variant="end">
            <Localize id="homePage.FooterKokolingoButton" />
          </Button>
          <Localize.Img
            titleID="homePage.KokoTheParrot"
            altID="homePage.KokoTheParrot"
            src={kokoTheParrot1}
            className="papiga1"
          />
          <Localize.Img
            titleID="homePage.Jungle"
            altID="homePage.Jungle"
            src={jungle}
            className="trava"
          />
        </div>
      </div>
    </Page>
  );
};

export default IndexPage;
