import React from 'react';
import { useIntlMessages } from '@kokolingo/localisation';

import {
  AcademyAdditionalInfo,
  AcademyDetails,
  AcademyFAQ,
  AcademyHeader,
  AcademyIntro,
  AcademyPricing,
  AcademyReviews,
  PageNew,
} from '../../../fragments';

const Academy = () => {
  const messages = useIntlMessages();

  return (
    <PageNew
      title={messages.academy}
      description=""
      headline=""
      wrapper={(props) => <div {...props} />}
    >
      <AcademyHeader />
      <AcademyIntro />
      <AcademyDetails />
      <AcademyFAQ />
      <AcademyPricing />
      <AcademyAdditionalInfo />
      <AcademyReviews />
    </PageNew>
  );
};

export default Academy;
