import React from 'react';
import { Localize } from '@kokolingo/localisation';

import { PageNew, FAQnew, FAQItems } from '../../../fragments';

import './index.scss';

const FAQ = () => {
  return (
    <PageNew
      title="Kokolingo FAQ"
      description="Frequently asked questions about Kokolingo."
      headline={<Localize id="faqsPage.title" />}
    >
      <FAQnew answers={FAQItems} />
    </PageNew>
  );
};

export default FAQ;
