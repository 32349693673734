import * as React from 'react';
import { Localize, useIntlMessages } from '@kokolingo/localisation';
import { Title, BackToTopButton } from '../../../components';
import { Page, FAQ } from '../../../fragments';

import './index.css';

const FrequentlyAskedQuestions = () => {
  const messages = useIntlMessages();

  return (
    <Page
      title={messages.meta.FAQKokolingoTitle}
      description={messages.meta.FAQKokolingoDescription}
    >
      <div className="faq-page-block">
        <Title variant="dark">
          <Localize id="FAQ.Title" />
        </Title>
        <FAQ />
        <BackToTopButton />
      </div>
    </Page>
  );
};

export default FrequentlyAskedQuestions;
