import React from 'react';
import { useIntlMessages } from '@kokolingo/localisation';

import {
  AcademyInstitutionDetails,
  AcademyInstitutionHeader,
  AcademyInstitutionIntro,
  AcademyInstitutionPricing,
  AcademyInstitutionAdditionalInfo,
  AcademyInstitutionReviews,
  AcademyInstitutionFAQ,
  PageNew,
} from '../../../fragments';

const AcademyInstitution = () => {
  const messages = useIntlMessages();

  return (
    <PageNew
      title={messages.academyInstitution}
      description=""
      headline=""
      wrapper={(props) => <div {...props} />}
    >
      <AcademyInstitutionHeader />
      <AcademyInstitutionIntro />
      <AcademyInstitutionDetails />
      <AcademyInstitutionFAQ />
      <AcademyInstitutionPricing />
      <AcademyInstitutionAdditionalInfo />
      <AcademyInstitutionReviews />
    </PageNew>
  );
};

export default AcademyInstitution;
