import * as React from 'react';

import { Page, PrivacyPolicy } from '../../../fragments';

const PrivacyPolicyPage = () => {
  return (
    <Page title="Kokolingo">
      <PrivacyPolicy />
    </Page>
  );
};

export default PrivacyPolicyPage;
