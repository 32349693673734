import * as React from 'react';

import { PrivacyPolicy, PageNew } from '../../../fragments';

const PrivacyPolicyPage = () => {
  return (
    <PageNew title="Kokolingo" headline="E-Glas Privacy Policy">
      <PrivacyPolicy />
    </PageNew>
  );
};

export default PrivacyPolicyPage;
