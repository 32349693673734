import * as React from 'react';
import { PageNew } from '../../../fragments';

const SpeechTherapyAssessment = () => {
  return (
    <PageNew
      headline="Speech Therapy Assessment"
      description=""
      title="Kokolingo - Speech Therapy Assessment"
    />
  );
};

export default SpeechTherapyAssessment;
