import React from 'react';
import { useIntlMessages } from '@kokolingo/localisation';

import {
  SectionHeader,
  SectionIntro,
  SectionShortAbout,
  SectionVideoAbout,
  SectionSounds,
  SectionActivities,
  SectionFooter,
  SectionCustomers,
  SectionPricing,
  SectionHowToStart,
  SectionFAQ,
  SectionBlog,
  PageNew,
} from '../../../fragments';

import './index.scss';

const NewHome = () => {
  const messages = useIntlMessages();
  return (
    <PageNew
      title="Kokolingo"
      description={messages.meta.KokolingoDescription}
      wrapper={(props) => <div {...props} />}
      headline=""
    >
      <SectionHeader />
      <SectionIntro />
      <SectionShortAbout />
      <SectionVideoAbout videoSrc="https://kokolingo-us-data.s3.amazonaws.com/common/KOKOLINGO_PROMO_DIGITAL.mp4" />
      <SectionSounds />
      <SectionActivities />
      <SectionCustomers />
      <SectionHowToStart />
      <SectionPricing />
      <SectionFAQ />
      <SectionBlog />
      <SectionFooter />
    </PageNew>
  );
};

export default NewHome;
